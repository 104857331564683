<template>
    <div class="edu-frame">
        <edu-nav-bar title="选择系/专业" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="dept-list">
            <van-button v-for="(dep, index) in deps" :key="index" class="dept-item" block round type="info" @click="toXzkc(dep.depId)">{{dep.depName}}</van-button>
        </div>
    </div>
</template>
<script>
    import {Button} from 'vant';
    import EduNavBar from "@/components/EduNavBar";
    import BaomingApi from "@/api/BaomingApi";
    import Tools from "../api/Tools";
    export default {
        components: {
            EduNavBar,
            VanButton: Button
        },
        data() {
            return {
                show: false,
                activeKey: 0,
                cust: Tools.getCurCust(),
                deps: [],
                termid: null,
                term: null,
                termTime: null
            }
        },
        methods: {
            toXzkc(depId) {
                this.$router.push({name: 'xzkc', query: { depId: depId, term: this.term, termTime: this.termTime }});
            }
        },
        mounted() {
            // 获取报读时段
            this.term = this.$route.query.term
            this.termTime = this.$route.query.termtime
            this.termid = this.$route.query.termid
            var self = this
            if (this.term == undefined || this.termTime == undefined || this.term == null || this.termTime == null) {
                this.$dialog.alert({ message: '未能确定您所选的报读时段，请重新选择。' }).then(()=>{
                    self.$router.back()
                })
                return
            } else {
                BaomingApi.getDepts({ termId: this.termid, term: this.term, termTime: this.termTime, loginType: this.cust.loginType }).then(response => {
                   this.deps = response.res
                })
            }
        }
    }
</script>
<style scoped>
    .dept-list {
        height: calc(100vh - 46px);
        width: 100%;
        position: relative;
        overflow: auto;

    }
    .dept-item {
        width: 90%;
        margin: 10px auto 5px;
    }
</style>
